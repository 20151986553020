// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { listDays } from "../../actions/dayActions";

// Internal Components & Utilities
import Confetti from "../../components/Animations/Confetti";
import quoteObj from "../../components/Quotes/Quotes";
import {
  MemoryIcon,
  QuoteIconV2,
  ValuesIcon,
  VitIcon,
} from "../../components/Icons/Icon";
import { dateFormatter, memorySelector } from "../../utils/UtilityFunctions";

// Styling
import "./pageloading.css";

const TileLoading = () => {
  // Hooks
  const dispatch = useDispatch();

  // State
  const [tileTextState, setTileTextState] = useState("");
  const [quoteAuthor, setQuoteAuthor] = useState("");
  const [quote, setQuote] = useState(0);
  const [memory, setMemory] = useState();
  const [celebration, setCelebration] = useState(false);
  const [quoteOrMemory, setQuoteOrMemory] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dayList = useSelector((state) => state.dayList);
  const { loading, error, days } = dayList;

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo]);

  const currentWindow = window.location.href;

  const [coinFlip, setCoinFlip] = useState(Math.random());
  const [coinFlipTwo, setCoinFlipTwo] = useState(Math.random());

  const quoteSelector = () => {
    setQuote(Math.floor(Math.random() * quoteObj.length));
  };

  useEffect(() => {
    if (days) {
      setMemory(memorySelector(days));
    }
  }, [days, memorySelector]);

  const dayChecker = () => {
    if (days) {
      let totalDays = days.length;

      if (totalDays === 0 && coinFlip) {
        // Welcome Users and Instruct to click on book icon
        setTileTextState(`Welcome to your NoWastedDays!`);
        setQuoteAuthor("Start your first day by clicking on the book icon.");
      } else if (
        totalDays === 1 &&
        coinFlip &&
        currentWindow.match("/today/")
      ) {
        // Congratulate to complete first day
        setTileTextState(
          `You've started you're first day! Well Done! Have you heard of the #NWDsChallenge? Complete 10 days and share on social to get £10!.`
        );
        setCelebration(true);
        setTimeout(() => {
          setCelebration(false);
        }, 4000);
        setQuoteAuthor("");
      } else if (totalDays === 1 && coinFlip < 0.5) {
        // Share quote
        setQuoteOrMemory("quote");
        setTileTextState(`"${quoteObj[quote].quote}"`);
        setQuoteAuthor(`${quoteObj[quote].author}`);
      } else if (totalDays === 1 && coinFlip >= 0.5) {
        setTileTextState(
          `Start to build the habit by logging a day tomorrow. Don't worry if you accidentally miss a day either. That is also an insight that might help you in the future!`
        );
        setQuoteAuthor("");
        setQuoteOrMemory("");
      } else if (totalDays >= 2 && totalDays <= 9 && coinFlip >= 0.5) {
        setTileTextState(
          `Complete ${10 - totalDays} more days to activate your insights.`
        );
        setQuoteAuthor("");
        setQuoteOrMemory("");
      } else if (totalDays >= 2 && totalDays <= 9 && coinFlip < 0.5) {
        if (coinFlipTwo >= 0.5) {
          setQuoteOrMemory("memory");
          let mem = memory && memory.memory;
          let date = memory && memory.date;
          setTileTextState(mem);
          setQuoteAuthor(date);
        } else {
          setQuoteOrMemory("quote");
          setTileTextState(`"${quoteObj[quote].quote}"`);
          setQuoteAuthor(`${quoteObj[quote].author}`);
        }
      } else if (totalDays === 10) {
        setTileTextState(`Your insights are live! Go check them out.`);
        setQuoteAuthor("");
        setCelebration(true);
        setTimeout(() => {
          setCelebration(false);
        }, 4000);
      } else if (totalDays >= 10 && totalDays < 17) {
        if (coinFlip >= 0.5) {
          let mem = memory && memory.memory;
          let date = memory && memory.date;
          setTileTextState(mem);
          setQuoteAuthor(date);
          setQuoteOrMemory("memory");
        } else {
          setTileTextState(`"${quoteObj[quote].quote}"`);
          setQuoteAuthor(`${quoteObj[quote].author}`);
          setQuoteOrMemory("quote");
        }
      } else if (totalDays === 17) {
        setTileTextState(
          `You've unlocked another week of insight data. Check out your graphs`
        );
        setQuoteAuthor("");
        setCelebration(true);
        setTimeout(() => {
          setCelebration(false);
        }, 4000);
      } else if (totalDays > 17 && totalDays % 7 === 0) {
        setTileTextState(
          `You've unlocked another week of insight data. Check out your graphs`
        );
        setQuoteAuthor("");
        setCelebration(true);
        setTimeout(() => {
          setCelebration(false);
        }, 4000);
      } else if (totalDays > 17 && coinFlip >= 0.5) {
        setTileTextState(memory.memory);
        setQuoteAuthor(memory.date);
        setQuoteOrMemory("memory");
      } else if (totalDays > 17 && coinFlip < 0.5) {
        setTileTextState(`"${quoteObj[quote].quote}"`);
        setQuoteAuthor(`${quoteObj[quote].author}`);
        setQuoteOrMemory("quote");
      }
    }
  };

  useEffect(() => {
    dayChecker();
    if (days) {
      setMemory(memorySelector(days));
    }
  }, [days]);

  useEffect(() => {
    quoteSelector();
  }, []);

  const [pageLoading, setPageLoading] = useState(true);

  const loadingTimeout = () => {
    let randNum = 1250;
    setTimeout(() => {
      if (days) {
        setPageLoading(false);
      }
    }, randNum);
  };

  useEffect(() => {
    loadingTimeout();
  });

  const renderLoadingBlock = () => {
    if (!days) {
      return;
    }

    if (pageLoading) {
      return (
        <div className="loading__wrapper">
          <div className="loading__container-v1">
            <h2>Loading...</h2>
            {/* <h5>{quoteAuthor}</h5> */}
          </div>
          <div className="loading--icon">
            <ValuesIcon />
          </div>
        </div>
      );
    } else {
      if (quoteOrMemory === "quote") {
        return (
          <div className="loading__wrapper">
            <div className="loading__container-v1">
              <h2>{tileTextState}</h2>
              <h5>{quoteAuthor}</h5>
            </div>
            <div className="loading--icon">
              <QuoteIconV2 />
            </div>
          </div>
        );
      } else if (quoteOrMemory === "memory") {
        return (
          <div className="loading__wrapper">
            <div className="loading__container-v2">
              <h2 style={{ width: "100%%" }}>{tileTextState}</h2>
              <div className="memory__div">
                <span>Memory from the Past. </span>{" "}
                <span>{dateFormatter(quoteAuthor)}</span>
              </div>
            </div>
            {/* <div className="loading--icon--memory">
              <MemoryIcon />
            </div> */}
          </div>
        );
      } else {
        return (
          <div className="loading__wrapper">
            <div className="loading__container-v1">
              {celebration ? <Confetti /> : <></>}
              <h2>{tileTextState}</h2>
              <h5>{quoteAuthor}</h5>
            </div>
            <div className="loading--icon">
              <VitIcon />
            </div>
          </div>
        );
      }
    }
  };

  return <div className="tileLoading">{renderLoadingBlock()}</div>;
};

export default TileLoading;
