// React
import React, { useState, useEffect } from "react";

// Props
import PropTypes from "prop-types";

// Internal Components
import Column from "./Column";

// External Compeonts
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../Styles/Item/Dropable";

import { motion } from "framer-motion";

const Board = ({
  day,
  isCombineEnabled,
  ordered,
  onDragEnd,
  initial,
  overlay,
  useClone,
  containerHeight,
  withScrollableColumns,
  handleScoreChange,
  handleColumnChange,
  handleUrgencyChange,
  handleSort,
  yearList,
  display,
  addSubTask,
  addStep,
  toggleSubTask,
  toggleStep,
  toggleOverlay,
  updateTitle,
  onDragEndSteps,
  deleteObjective,
  deleteStep,
  deleteSubtask,
  updateDescription,
  updateStep,
  updateNotes,
  viewWidth,
  viewType,
}) => {
  return (
    <>
      {day && viewType === "desktop" && (
        <motion.div
          id={"wektestday"}
          drag
          dragConstraints={{
            left: 0,
            right: viewWidth - 400,
            top: 0,
            bottom: 750,
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable
              droppableId="dead"
              type="COLUMN"
              direction="horizontal"
              ignoreContainerClipping={Boolean(containerHeight)}
              isCombineEnabled={isCombineEnabled}
            >
              {(provided) => (
                <div
                  className={"board--scale--day"}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {ordered &&
                    ordered.map((key, index) => (
                      <Column
                        columnType="day--desktop"
                        key={key}
                        index={index}
                        title={key}
                        order={ordered}
                        objective={initial[key]}
                        overlay={overlay}
                        onDragEndSteps={onDragEndSteps}
                        isScrollable={withScrollableColumns}
                        isCombineEnabled={isCombineEnabled}
                        useClone={useClone}
                        updateTitle={updateTitle}
                        handleScoreChange={handleScoreChange}
                        handleUrgencyChange={handleUrgencyChange}
                        handleSort={handleSort}
                        display={display}
                        yearList={yearList}
                        addSubTask={addSubTask}
                        addStep={addStep}
                        toggleSubTask={toggleSubTask}
                        toggleStep={toggleStep}
                        toggleOverlay={toggleOverlay}
                        deleteObjective={deleteObjective}
                        deleteStep={deleteStep}
                        deleteSubtask={deleteSubtask}
                        updateDescription={updateDescription}
                        updateStep={updateStep}
                        updateNotes={updateNotes}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </motion.div>
      )}

      {day && viewType !== "desktop" && (
        <div
          id={"wektestmobile"}
          data-name="background"
          onClick={handleColumnChange}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable
              droppableId="board"
              type="COLUMN"
              direction="horizontal"
              ignoreContainerClipping={Boolean(containerHeight)}
              isCombineEnabled={isCombineEnabled}
            >
              {(provided) => (
                <div
                  className={"board--scale"}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {ordered &&
                    ordered.map((key, index) => (
                      <Column
                        columnType="day--mobile"
                        key={key}
                        viewType={viewType}
                        index={index}
                        title={key}
                        order={ordered}
                        objective={initial[key]}
                        overlay={overlay}
                        isScrollable={withScrollableColumns}
                        isCombineEnabled={isCombineEnabled}
                        useClone={useClone}
                        updateTitle={updateTitle}
                        handleScoreChange={handleScoreChange}
                        handleUrgencyChange={handleUrgencyChange}
                        handleSort={handleSort}
                        display={display}
                        yearList={yearList}
                        addSubTask={addSubTask}
                        addStep={addStep}
                        toggleSubTask={toggleSubTask}
                        toggleStep={toggleStep}
                        toggleOverlay={toggleOverlay}
                        deleteObjective={deleteObjective}
                        deleteStep={deleteStep}
                        deleteSubtask={deleteSubtask}
                        updateDescription={updateDescription}
                        updateStep={updateStep}
                        updateNotes={updateNotes}
                        onDragEndSteps={onDragEndSteps}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </div>
      )}

      {!day && viewType !== "desktop" && (
        <div id={"wektest"}>
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable
              droppableId="dead"
              type="COLUMN"
              direction="horizontal"
              ignoreContainerClipping={Boolean(containerHeight)}
              isCombineEnabled={isCombineEnabled}
            >
              {(provided) => (
                <div
                  className={"board--scale"}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {ordered &&
                    ordered.map((key, index) => (
                      <Column
                        columnType="week--mobile"
                        viewType={viewType}
                        day={day}
                        key={key}
                        index={index}
                        title={key}
                        order={ordered}
                        objective={initial[key]}
                        overlay={overlay}
                        isScrollable={withScrollableColumns}
                        isCombineEnabled={isCombineEnabled}
                        useClone={useClone}
                        updateTitle={updateTitle}
                        handleScoreChange={handleScoreChange}
                        handleUrgencyChange={handleUrgencyChange}
                        handleSort={handleSort}
                        yearList={yearList}
                        display={display}
                        addSubTask={addSubTask}
                        addStep={addStep}
                        toggleSubTask={toggleSubTask}
                        toggleStep={toggleStep}
                        toggleOverlay={toggleOverlay}
                        deleteObjective={deleteObjective}
                        deleteStep={deleteStep}
                        deleteSubtask={deleteSubtask}
                        updateDescription={updateDescription}
                        updateStep={updateStep}
                        updateNotes={updateNotes}
                        onDragEndSteps={onDragEndSteps}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </div>
      )}

      {!day && viewType === "desktop" && (
        <div id={"wektest"}>
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable
              droppableId="board"
              type="COLUMN"
              direction="horizontal"
              ignoreContainerClipping={Boolean(containerHeight)}
              isCombineEnabled={isCombineEnabled}
            >
              {(provided) => (
                <div
                  className={"board--scale"}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {ordered &&
                    ordered.map((key, index) => (
                      <Column
                        columnType="week--desktop"
                        key={key}
                        index={index}
                        title={key}
                        order={ordered}
                        objective={initial[key]}
                        overlay={overlay}
                        isScrollable={withScrollableColumns}
                        isCombineEnabled={isCombineEnabled}
                        useClone={useClone}
                        updateTitle={updateTitle}
                        handleScoreChange={handleScoreChange}
                        handleUrgencyChange={handleUrgencyChange}
                        handleSort={handleSort}
                        yearList={yearList}
                        addSubTask={addSubTask}
                        addStep={addStep}
                        toggleSubTask={toggleSubTask}
                        toggleStep={toggleStep}
                        toggleOverlay={toggleOverlay}
                        deleteObjective={deleteObjective}
                        deleteStep={deleteStep}
                        deleteSubtask={deleteSubtask}
                        updateDescription={updateDescription}
                        updateStep={updateStep}
                        updateNotes={updateNotes}
                        onDragEndSteps={onDragEndSteps}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </div>
      )}
    </>
  );
};

Board.defaultProps = {
  isCombineEnabled: false,
};

Board.propTypes = {
  isCombineEnabled: PropTypes.bool,
};

export default Board;
