// React
import React, { useState } from "react";

// Internal Components
import AutoResizeTextArea from "../../components/AutoResizeTextarea/AutosizeTextarea";
import { continuationCalculator } from "../../utils/UtilityFunctions";

function YearTile({ tile_name, value, children, handleChange }) {
  const [tileState, setTileState] = useState({
    Name: "John Doer",
    Quote: "The mind has no answers. The heart has no questions.",
    Ikigai:
      "To empower the experience of life to commensurate with the nature of reality.",

    visionValuesState: [
      {
        inputName: {
          placeholder: "Effort",
        },
        inputDescription: {
          values: {
            placeholder: "Giving my best at every opportunity",
          },
          vision: {
            placeholder: "Learn a new language",
          },
        },
      },
      {
        inputName: {
          placeholder: "Attitude",
        },
        inputDescription: {
          values: {
            placeholder:
              "A joyful, mindful perspective in the context of daily courage",
          },
          vision: {
            placeholder: "Run a half marathon",
          },
        },
      },
      {
        inputName: {
          placeholder: "Character",
        },
        inputDescription: {
          values: {
            placeholder: "Resolute in adversity, humilitous in acclaim",
          },
          vision: {
            placeholder: "Visit 3 new countries",
          },
        },
      },
      {
        inputName: {
          placeholder: "Visionary",
        },
        inputDescription: {
          values: {
            placeholder: "Make the difference written on your bones",
          },
          vision: {
            placeholder: "Read 10 books",
          },
        },
      },
      {
        inputName: {
          placeholder: "Love",
        },
        inputDescription: {
          values: {
            placeholder: "Unconditionally support other people",
          },
          vision: {
            placeholder: "Be present",
          },
        },
      },
    ],
  });

  return (
    <div className={`yeartile ${tile_name}`} data-tooltip={`${tile_name}`}>
      <div className="tutorial access">
        <div className="iconDiv">
          <div className="newIcon">{children}</div>
          <label className="score_label">
            <strong
              style={{ textTransform: "capitalize" }}
            >{`${tile_name}`}</strong>
          </label>
        </div>
      </div>

      {Object.keys(tileState)
        .filter((tile) => tile.toLowerCase() === tile_name.toLowerCase())
        .map((t, i) => (
          <AutoResizeTextArea
            key={i}
            className="formInput year"
            wrap="soft"
            autoComplete="off"
            placeholder={tileState[t]}
            name={t.toLowerCase()}
            value={value}
            onChange={(e) => {
              handleChange(e, tile_name);
            }}
          />
        ))}

      {tile_name === "birthday" && (
        <input
          className="birthdayInput"
          type="date"
          value={value}
          placeholder=""
          name="birthdate"
          onChange={(e) => {
            handleChange(e, tile_name);
          }}
        />
      )}

      {tile_name === "continuation" && (
        <textarea
          id="cont"
          className="formInput year"
          wrap="soft"
          autoComplete="off"
          value={
            value
              ? `Today is unique. It is day number ${value} in my life so far.`
              : `Add your birthday and your continuation will be automatically calculated.`
          }
          placeholder=""
          readOnly
        />
      )}

      {tile_name === "memory" && (
        // Read only Input
        <AutoResizeTextArea
          id="cont"
          className="formInput year"
          wrap="soft"
          autoComplete="off"
          value={value.memory}
          placeholder=""
          readOnly
        />
      )}

      {(tile_name === "vision" || tile_name === "values") && (
        <div className="valuesRow">
          {tileState.visionValuesState?.map((div, index) => {
            let arr = ["one", "two", "three", "four", "five"];
            let nombre = arr[index];
            return (
              <div key={index} className="valuesDiv">
                {tile_name === "values" && (
                  <input
                    type="text"
                    wrap="soft"
                    autoComplete="off"
                    placeholder={div.inputName.placeholder}
                    title="title"
                    value={
                      value &&
                      value[nombre] &&
                      value[nombre].title &&
                      value[nombre].title
                    }
                    name={nombre}
                    onChange={(e) => {
                      handleChange(e, tile_name);
                    }}
                  />
                )}
                {tile_name === "values" && (
                  <textarea
                    wrap="soft"
                    autoComplete="off"
                    placeholder={div.inputDescription.values.placeholder}
                    title="description"
                    name={arr[index]}
                    value={
                      value &&
                      value[nombre] &&
                      value[nombre].description &&
                      value[nombre].description
                    }
                    onChange={(e) => {
                      handleChange(e, tile_name);
                    }}
                  />
                )}
                {tile_name === "vision" && (
                  <textarea
                    wrap="soft"
                    autoComplete="off"
                    placeholder={div.inputDescription.vision.placeholder}
                    title="description"
                    name={arr[index]}
                    value={
                      value &&
                      value[nombre] &&
                      value[nombre].description &&
                      value[nombre].description
                    }
                    onChange={(e) => {
                      handleChange(e, tile_name);
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default YearTile;
