//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

//Actions
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";
import { listDays } from "../../actions/dayActions";
import { createDayAction } from "../../actions/dayActions";

//Internal Components
import TestFooter from "../../layouts/Footer/TestFooter";

//External Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandPointRight,
  faX,
  faFloppyDisk,
  faPenToSquare,
  faEraser,
  faCircleCheck,
  faQuestion,
  faSquareCaretRight,
  faSquareCaretLeft,
  faSquareXmark,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { isToday } from "date-fns";
import {
  CreateDayIcon,
  CreateDayIconV2,
  ValuesIcon,
  NameIcon,
  NameIconV2,
  NameIconV3,
  NameIconV4,
  DobIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  YearIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  SaveIcon,
  TickIcon,
  PreviousDay,
  VitIcon,
  WeekIcon,
} from "../../components/Icons/Icon";

export default function CreateDayScreen({ history, props }) {
  // Form State - Today
  const [startScore, setStartScore] = useState(4.1);
  const [logDate, setLogDate] = useState("2023-01-03");
  const [thankYou, setThankYou] = useState(
    "my living room for providing me a warm, happy place to live and work."
  );
  const [selfLess, setSelfLess] = useState(
    "focusing on the present moment only."
  );
  const [mindBody, setMindBody] = useState("it is here with me");
  const [momentOne, setMomentOne] = useState(
    "Work Before Work: Read 40 pages of The Bluest Eye"
  );
  const [momentOneScore, setMomentOneScore] = useState(8.8);
  const [momentTwo, setMomentTwo] = useState(
    "Work: Be present and treat each task with curiousity and a sense of opportunity"
  );
  const [momentTwoScore, setMomentTwoScore] = useState(7.5);
  const [momentThree, setMomentThree] = useState(
    "Meditate - 20 mins > Stretch > 15 minutes"
  );
  const [momentThreeScore, setMomentThreeScore] = useState(5.5);
  const [momentFour, setMomentFour] = useState("Finish online learning module");
  const [momentFourScore, setMomentFourScore] = useState(10);
  const [momentFive, setMomentFive] = useState(
    "Listen to Brian Eno LP to wind down"
  );
  const [momentFiveScore, setMomentFiveScore] = useState(10);
  const [rememberToday, setRememberToday] = useState(
    "Working hard; taking care of what I could control and protecting my recovery / relax time."
  );
  const [leaveBehind, setLeaveBehind] = useState(
    "Not doing recovery earlier in the day"
  );
  const [onMyMind, setOnMyMind] = useState(
    "One of those days I have learned to overcome"
  );
  const [endScore, setEndScore] = useState(4.5);

  // Loading State
  const [pageLoading, setPageLoading] = useState(true);
  const [buttonState, setButtonState] = useState(false);

  //Animation State
  const [isIntro, setIsIntro] = useState(true);
  const [show, setShow] = useState(false);
  const [isPrimes, setIsPrimes] = useState(false);
  const [isMoments, setIsMoments] = useState(false);
  const [isEvenings, setIsEvenings] = useState(false);
  const [beforeScore, setBeforeScore] = useState(false);
  const [finalScore, setFinalScore] = useState(false);
  const [thankYouState, setThankYouState] = useState(false);
  const [selfLessState, setSelfLessState] = useState(false);
  const [mindBodyState, setMindBodyState] = useState(false);
  const [rememberTodayState, setRememberTodayState] = useState(false);
  const [leaveBehindState, setLeaveBehindState] = useState(false);
  const [momentActive, setMomentActive] = useState(false);

  const showHideState = {
    show: {
      opacity: 1,
      transition: {
        duration: 1,
        ease: "easeIn",
      },
    },
    hide: {
      opacity: 0,
      transition: {
        duration: 1,
        ease: "easeOut",
      },
    },
  };

  const introState = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      height: "100%",
      width: "100%",
    },
    open: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeIn",
      },
      height: "100%",
      width: "100%",
    },
    closed: {
      opacity: 0,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
      height: "100%",
      width: "100%",
    },
  };

  const activeState = {
    hidden: {
      backgroundColor: "rgba(0, 0, 0, 0)",
      padding: "4px 6px 4px 6px",
      borderRadius: "0 0 5px 5px",
      opacity: 0,
      color: "rgba(0, 74, 35,1)",
      fontWeight: 400,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      color: "rgba(245,245,245,1)",
      fontWeight: "600",
      padding: "6px 8px 6px 8px",
      borderRadius: "0 0 5px 5px",
      boxSizing: "border-box",
      backgroundColor: "rgba(103, 36, 131, 1)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      scale: 1,
      padding: "4px 6px 4px 6px",
      borderRadius: "0 0 5px 5px",
      color: "rgba(0, 74, 35,1)",
      fontWeight: "400",
      opacity: 1,
      backgroundColor: "rgba(0, 0, 0, 0)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const activeStateMoment = {
    hidden: {
      backgroundColor: "rgba(245, 245, 245, 1)",
      padding: "4px 6px 4px 6px",
      borderRadius: "5px 5px 0 0",
      opacity: 0,
      color: "rgba(0, 74, 35,1)",
      fontWeight: 400,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      color: "rgba(245,245,245,1)",
      fontWeight: "600",
      padding: "6px 8px 6px 8px",
      borderRadius: "5px 5px 0 0",
      boxSizing: "border-box",
      backgroundColor: "rgba(103, 36, 131, 1)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      scale: 1,
      padding: "4px 6px 4px 6px",
      borderRadius: "5px 5px 0 0",
      color: "rgba(0, 74, 35,1)",
      fontWeight: "400",
      opacity: 1,
      backgroundColor: "rgba(245, 245, 245, 1)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const activeStateRange = {
    hidden: {
      backgroundColor: "rgba(0, 0, 0, 0)",
      padding: "0px",
      borderRadius: "0 0 0 5px",
      opacity: 0,
      color: "rgba(0, 74, 35,1)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      color: "rgba(0, 74, 35,1)",
      padding: "4px",
      borderRadius: "0 0 0 5px",
      backgroundColor: "rgba(226, 223, 207,1)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      scale: 1,
      padding: "0px",
      borderRadius: "0 0 0 5px",
      color: "rgba(0, 74, 35,1)",
      opacity: 1,
      backgroundColor: "rgba(0, 0, 0, 0)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const fontActiveState = {
    hidden: {
      backgroundColor: "rgba(0, 74, 35, 0)",
      color: "rgba(0, 74, 35,1)",
      padding: "4px 6px 4px 6px",
      fontWeight: "400",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      padding: "4px 6px 4px 6px",
      color: "rgba(226, 223, 207,1)",
      fontWeight: "600",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      scale: 1,
      padding: "4px 6px 4px 6px",
      borderRadius: "5px",
      color: "rgba(0, 74, 35,1)",
      fontWeight: "400",
      opacity: 1,
      backgroundColor: "rgba(0, 0, 0, 0)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  // Data Calls & Event Handlers
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const loadingTimeout = () => {
    setTimeout(() => {
      setPageLoading(false);
    }, 3000);
  };

  const [instructionState, setInstructionState] = useState("");
  const [supportingState, setSupportingState] = useState("");
  const [supportingStateTwo, setSupportingStateTwo] = useState("");
  const [clickCounter, setClickCounter] = useState(0);
  const [successState, setSuccessState] = useState(" .active");

  const getBackgroundStyle = (data) => {
    const percent = (data / 10) * 100; // Calculate percentage value
    return `linear-gradient(to right, rgba(0, 74, 35, 0.5) ${percent}%, transparent ${percent}%)`;
  };

  const getBackgroundStyleTwo = (data) => {
    const percent = (data / 5) * 100; // Calculate percentage value
    return `linear-gradient(to right, rgba(0, 74, 35, 0.5) ${percent}%, transparent ${percent}%)`;
  };

  const clickPlus = () => {
    setClickCounter((clickCounter) => clickCounter + 1);
  };

  const clickMinus = () => {
    setClickCounter((clickCounter) => clickCounter - 1);
  };

  const displayText = () => {
    if (clickCounter === 0) {
      setInstructionState(
        (instructionState) => "NoWastedDays is a digital journal."
      );
      setSupportingState((supportingState) => "Click or tap to get started");
    } else if (clickCounter === 1) {
      setInstructionState(
        (instructionState) =>
          "The diary provides a great framework for designing a day."
      );
      setSupportingState((supportingState) => "");
    } else if (clickCounter === 2) {
      setInstructionState(
        (instructionState) => "Each day in the diary has 3 different pages."
      );
      setShow((show) => true);
      setIsPrimes((isPrimes) => true);
      setIsMoments((isMoments) => true);
      setIsEvenings((isEvenings) => true);
    } else if (clickCounter === 3) {
      setInstructionState((instructionState) => "Page #1: The Priming Page");
      setSupportingState(
        (supportingState) =>
          "To help focus and free your mind for the day ahead."
      );

      setTimeout(() => {
        setThankYouState((thankYouState) => true);
        setSelfLessState((selfLessState) => true);
        setMindBodyState((mindBodyState) => true);
      }, 500);
    } else if (clickCounter === 4) {
      setInstructionState((instructionState) => "Page #2: The Planning Page");
      setSupportingState(
        (supportingState) => "To give each day clear priorities and purpose."
      );

      setThankYouState((thankYouState) => false);
      setSelfLessState((selfLessState) => false);
      setMindBodyState((mindBodyState) => false);
      setTimeout(() => {
        setMomentActive((momentActive) => true);
      }, 500);
    } else if (clickCounter === 5) {
      setInstructionState(
        (instructionState) => "Page #3: The Perspective Page"
      );
      setSupportingState(
        (supportingState) =>
          "To help distill your day and filter out its worst thoughts and feelings."
      );

      setMomentActive((momentActive) => false);
      setTimeout(() => {
        setRememberTodayState((rememberTodayState) => true);
        setLeaveBehindState((leaveBehindState) => true);
      }, 500);
    } else if (clickCounter === 6) {
      setInstructionState(
        (instructionState) => "Here is how to complete a day in your diary"
      );
      setSupportingState((supportingState) => "");
      setRememberTodayState((rememberTodayState) => false);
      setLeaveBehindState((leaveBehindState) => false);
    } else if (clickCounter === 7) {
      setInstructionState(
        (instructionState) =>
          "In the morning, check-in with how you are feeling using the slider."
      );
      setSupportingState(
        (supportingState) =>
          "Simply try and capture how you feeling at that very moment."
      );
      setBeforeScore((beforeScore) => true);
      scoreAnimator();
    } else if (clickCounter === 8) {
      setInstructionState(
        (instructionState) =>
          "After that, give a nice bit of thanks to something."
      );
      setSupportingState(
        (supportingState) =>
          "Giving gratitude helps the mind focus on the positive and this has been shown to reduce stress and increase joy over the course of a day."
      );
      setBeforeScore((beforeScore) => false);
      setThankYouState((thankYouState) => true);
      scoreAnimator();
    } else if (clickCounter === 9) {
      setInstructionState(
        (instructionState) =>
          "Then, try to detach from the idea of your 'self'."
      );
      setSupportingState(
        (supportingState) =>
          "The idea that you are you, and that you have a past and a future, is as much something we imagine as it is a fundamental fact of our lives. Detaching from the idea has been shown to help you feel more present and mindful over an entire day."
      );
      setSupportingStateTwo((supportingStateTwo) => "");
      setThankYouState((thankYouState) => false);
      setSelfLessState((selfLessState) => true);
      scoreAnimator();
    } else if (clickCounter === 10) {
      setInstructionState(
        (instructionState) =>
          "Lastly, say why you are pleased to live in your mind and body."
      );
      setSupportingState(
        (supportingState) =>
          "Being regularly and sincerely positive about ourselves builds genuine self-assurance without enlarging ego."
      );
      setSupportingStateTwo((supportingStateTwo) => "");
      setSelfLessState((selfLessState) => false);
      setMindBodyState((mindBodyState) => true);
      scoreAnimator();
    } else if (clickCounter === 11) {
      setInstructionState(
        (instructionState) =>
          "After page #1 is complete, it is time to plan out your day."
      );
      setSupportingState((supportingState) => "");
      setSupportingStateTwo((supportingStateTwo) => "");
      setMindBodyState((mindBodyState) => false);
      scoreAnimator();
    } else if (clickCounter === 12) {
      setInstructionState(
        (instructionState) =>
          "Your NoWastedDays can give you loads of support making smart, simple plans focused on your joy and growth."
      );
      setSupportingState(
        (supportingState) =>
          "How this happens is explained in the next section."
      );
      scoreAnimator();
    } else if (clickCounter === 13) {
      setInstructionState(
        (instructionState) =>
          "Throughout the day, mark your progress using the respective sliders."
      );
      setSupportingState((supportingState) => "");
      setMomentActive((momentActive) => true);
      scoreAnimator();
    } else if (clickCounter === 14) {
      setInstructionState(
        (instructionState) =>
          "Then, in the evening, put your day into perspective."
      );
      setMomentActive((momentActive) => false);
      scoreAnimator();
    } else if (clickCounter === 15) {
      setInstructionState(
        (instructionState) =>
          "Capture the memories that will help you remember it in the future."
      );
      setSupportingState(
        (supportingState) =>
          "Consolidating a day has always been a powerful part of a diary but plays an even more poignant role in your NoWastedDays as we will soon learn."
      );
      setSupportingStateTwo((supportingStateTwo) => "");
      setRememberTodayState((rememberTodayState) => true);
      scoreAnimator();
    } else if (clickCounter === 16) {
      setInstructionState(
        (instructionState) =>
          "Then, share the thoughts and feelings you would rather leave behind."
      );
      setSupportingState(
        (supportingState) =>
          "By acknowledging and accepting the thoughts or features of our day that we don't like, we can move on from them more healthily and quickly."
      );
      setSupportingStateTwo((supportingStateTwo) => "");
      scoreAnimator();
      setRememberTodayState((rememberTodayState) => false);
      setLeaveBehindState((leaveBehindState) => true);
    } else if (clickCounter === 17) {
      setInstructionState(
        (instructionState) =>
          "Finally, give your day a score out of 5 based on how you felt."
      );
      setSupportingState((supportingState) => "");
      setSupportingStateTwo((supportingStateTwo) => "");
      setLeaveBehindState((leaveBehindState) => false);
      setFinalScore((finalScore) => true);
      setTimeout(() => {
        scoreAnimator();
      }, 1200);
    } else if (clickCounter === 18) {
      setInstructionState(
        (instructionState) =>
          "This 3 page format helps make for better days, but it is only scratching the surface of how your NoWastedDays can help you."
      );
      setSupportingState(
        (supportingState) => "Click the purple arrow to learn more."
      );
      setButtonState((buttonState) => true);
    }
  };

  const vw = Math.max(document.documentElement.clientWidth);

  const [viewWidth, setViewWidth] = useState(vw);
  const [fiveScore, setFiveScore] = useState(0);
  const [date, setDate] = useState();
  const [gratitude, setGratitude] = useState();
  const [detach, setDetach] = useState();
  const [bodyMind, setBodyMind] = useState();
  const [m1, setM1] = useState();
  const [m2, setM2] = useState();
  const [m3, setM3] = useState();
  const [m4, setM4] = useState();
  const [m5, setM5] = useState();
  const [s1, setS1] = useState(0);
  const [s2, setS2] = useState(1);
  const [s3, setS3] = useState(2);
  const [s4, setS4] = useState(3);
  const [s5, setS5] = useState(4);
  const [rT, setRT] = useState();
  const [lB, setLB] = useState();
  const [onMind, setOnMind] = useState();

  let datesArr = [
    "2023-01-02",
    "2023-01-03",
    "2023-01-04",
    "2023-01-05",
    "2023-01-06",
    "2023-01-07",
    "2023-01-08",
    "2023-01-09",
    "2023-01-10",
    "2023-01-11",
    "2023-01-12",
    "2023-01-02",
    "2023-01-03",
    "2023-01-04",
    "2023-01-05",
    "2023-01-06",
    "2023-01-07",
    "2023-01-08",
    "2023-01-09",
    "2023-01-10",
    "2023-01-11",
    "2023-01-12",
  ];

  let thankYouArr = [
    "my friends and family",
    "my home for keeping me warm and safe",
    "my beauty for bringing love",
    "my feet for getting me around everywhere",
    "that flower on my way to work",
    "Rhodri - for being a beautiful music freak",
    "David Hieatt for the inspiration yesterday",
    "Clever writers of Comedy",
    "William Morris",
    "that SpreadLoveMovement dude for the good thoughts",
    "my home: for safely hosting my life",
    "the dude cleaning out the pond",
    "Walter; for getting me in and out of work everyday",
    "this moment now; alone, awake early, cup of tea brewing; lovely",
    "Mike Posner - for his reflections, music and positivity",
  ];

  let selfLessArr = [
    "focusing on one thing at a time",
    "taking each moment for play and exploration",
    "simply being curious about everything",
    "taking the day one moment at a time",
    "breathing in the good shit, and letting go of the bad shit",
    "Meditating and aligning with the present",
    "seeing all things as opportunities for exploration",
    "by talking it into existence",
    "planning precisely and settling down to work with headspace",
    "switching off when it matters",
    "focusing on what matters with a spirit of problems as opportunities",
    "being up early; giving myself to what matters",
    "remembering that ego is the enemy",
    "just doing; thought can get in the way sometimes",
  ];
  let mindBodyArr = [
    "I do not have a toothache",
    "it views the world with hope and love",
    "it is free from pain most of the time",
    "it is here with me today",
  ];
  let momentsArr = [
    "Read for 45 minutes",
    "Meditate - 20 mins",
    "Switch off and go for a run",
    "Be present with my beauty",
    "Be present at art class",
    "Go for a 20 minute walk with a coffee",
    "Visit the Tate and take a deep breath",
    "Work before work: 90 mins of Coding",
    "If stress, take 5 breaths to reset",
    "Workout: Back & Biceps (250 Reps)",
    "Enjoy a skate round the park",
    "Be present at yoga class",
    "Speak to Mum and ask her how she is feeling",
    "Be present with the boys",
    "Be present at date night",
    "Prepare for Football practice",
    "Listen to Stravinsky for 60 minutes",
    "Be kind and work hard on my shift",
    "Have fun at football",
    "Take 15 minutes to look after the house plants",
    "No single use plastic today",
    "Be present making dinner",
    "Enjoy my online course",
    "Notice if I get angry",
    "Be present round Elenas",
    "Plan for the weekend",
    "Organise date night",
    "Be kind",
    "Start a new book",
    "Paint for 90 minutes",
  ];
  let memoriesArr = [
    "Coding progress; relaxing at work; fun chat after football",
    "Read all the books courtesy of COVID, Grid book, swag book, william morris book",
    "Good day; coffee and sandwich in MokaPot House; coded a lot; good exercise; good reading",
    "Lots of good exercise; fixing Maras jacket pocket; listening to Stephen Sondheim documentaries",
    "working in West Land talking about the Elizabeth line, smashing through coding, thinking about Shala brand with beauty",
    "Nice connection with Louise; going to Abel Selaocoe with Matt, Mara and Em; that pizza",
    "seeing the broken window at Westland, great midday chat with Toby, good afternoon chat with Will, evening out in China town with Petal <3",
    "Putting bricks down left right and centre; sitting in the back garden with the sushi",
    "Tasting in Monicas garden (her dog, and spraying the bottle everywhere), fun at football (feeling good in possession)",
    "Up early at Maccers - good work; good chat to Paul about Shala; nice to get paid by Refill; spirtual teaching on the label front; nice to watch race with beauty",
    "Joy of a lifetime stuff - hugging Nana, talking to Caspar, hearing the kids laugh, watching them express themselves, spending time together",
    "Going to Hucks, and going to Beaten by a Whisker; Making some good progress, generally enjoying exploring by myself",
    "Great work session pre-work. Solid work day. Good podcast listening with Chris Kamara, Joe Wicks and the notonthehighstreet woman. Good progress from beauty.",
    "good play on codepen in the morning; solid workday; nice chat with Skipp dev guy in evening; looking at leftist stickers ",
    "Nice to really move Shala along; listened to some strong John Hegarty, Rory and Jacob Collier talks.",
    "Cycling in the rain; having some interesting ideas; tackling the day well; that chat with Jamie and Louise; having a good last hour; lasagne and potato salad; reading Status Anxiety and the book of quotes",
  ];
  let leaveBehindArr = [
    "being trepidatious about anything. You can do it, or survive the reality of not doing it",
    "Rattling my brain through phone; never worth it",
    "any performance anxiety; just break it down and enjoy it one moment at a time. What are you rushing for?",
    "Worry about time passing by / not getting up early; you can never cheat the about of rest you need.",
    "Worrying about anything; just focus on things you can control and control them until they are controlled",
    "Phone vortex detracting from meditation time.",
    "Worrying like a people pleaser.",
    "being short on patience generally.",
    "any anxiety",
    "Fatigue; just breathe deeply to reset",
    "having any anxiety about anything; see it as an opportunity for growth and learning.",
    "being envious or resentful of other peoples achievements.",
    "not priming the day earlier for clear thought throughout.",
    "worrying about what anyone else thinks",
  ];

  let onMyMindArr = [
    "being trepidatious about anything. You can do it, or survive the reality of not doing it",
    "Rattling my brain through phone; never worth it",
    "Worrying like a people pleaser.",
    "worrying about what anyone else thinks",
  ];

  let randomArraySelector = (arr) => {
    let num;

    num = Math.floor(Math.random() * arr.length);

    return num;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setFiveScore((prevScore) => {
        if (prevScore >= 5) {
          return 0;
        }
        return prevScore + 0.25;
      });

      setDate(datesArr[randomArraySelector(datesArr)]);
      setGratitude(thankYouArr[randomArraySelector(thankYouArr)]);
      setDetach(selfLessArr[randomArraySelector(selfLessArr)]);
      setBodyMind(mindBodyArr[randomArraySelector(mindBodyArr)]);
      setM1(momentsArr[randomArraySelector(momentsArr)]);
      setM2(momentsArr[randomArraySelector(momentsArr)]);
      setM3(momentsArr[randomArraySelector(momentsArr)]);
      setM4(momentsArr[randomArraySelector(momentsArr)]);
      setM5(momentsArr[randomArraySelector(momentsArr)]);
      setS1((prevScore) => {
        if (prevScore >= 10) {
          return 0;
        }
        return prevScore + 0.25;
      });
      setS2((prevScore) => {
        if (prevScore >= 10) {
          return 0;
        }
        return prevScore + 0.25;
      });
      setS3((prevScore) => {
        if (prevScore >= 10) {
          return 0;
        }
        return prevScore + 0.25;
      });
      setS4((prevScore) => {
        if (prevScore >= 10) {
          return 0;
        }
        return prevScore + 0.25;
      });
      setS5((prevScore) => {
        if (prevScore >= 10) {
          return 0;
        }
        return prevScore + 0.25;
      });
    }, 1000);

    setRT(memoriesArr[randomArraySelector(memoriesArr)]);
    setLB(leaveBehindArr[randomArraySelector(leaveBehindArr)]);
    setOnMind(onMyMindArr[randomArraySelector(onMyMindArr)]);

    return () => clearInterval(interval); // Cleanup function to clear interval on unmount
  }, []);

  const scoreAnimator = () => {
    // let fiveScore = Math.floor(Math.random() * 50) / 10;
    let tenScoreOne = Math.floor(Math.random() * 100) / 10;
    let tenScoreTwo = Math.floor(Math.random() * 100) / 10;
    let tenScoreThree = Math.floor(Math.random() * 100) / 10;
    let tenScoreFour = Math.floor(Math.random() * 100) / 10;
    let tenScoreFive = Math.floor(Math.random() * 100) / 10;

    if (clickCounter === 7) {
      setStartScore(fiveScore);
      setLogDate(date);
    } else if (clickCounter === 8) {
      setThankYou(gratitude);
    } else if (clickCounter === 9) {
      setSelfLess(detach);
    } else if (clickCounter === 10) {
      setMindBody(bodyMind);
    } else if (clickCounter === 11) {
      setMomentOne(m1);
      setMomentTwo(m2);
      setMomentThree(m3);
      setMomentFour(m4);
      setMomentFive(m5);
    } else if (clickCounter === 12) {
    } else if (clickCounter === 13) {
      setMomentOneScore(s1);
      setMomentTwoScore(s2);
      setMomentThreeScore(s3);
      setMomentFourScore(s4);
      setMomentFiveScore(s5);
    } else if (clickCounter === 15) {
      setRememberToday(rT);
    } else if (clickCounter === 16) {
      setLeaveBehind(lB);
      setOnMyMind(onMind);
    } else if (clickCounter === 17) {
      setEndScore((endScore) => fiveScore);
    }
  };

  useEffect(() => {
    displayText();
  });

  const handleChange_onMyMind = (e) => {
    setOnMyMind(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const [value, setValue] = useState(50); // Initial value for the range slider
  const [tooltipPositionStart, setTooltipPositionStart] = useState(0);
  const [tooltipPositionEnd, setTooltipPositionEnd] = useState(0);
  const [tooltipPositionOne, setTooltipPositionOne] = useState(0);
  const [tooltipPositionTwo, setTooltipPositionTwo] = useState(0);
  const [tooltipPositionThree, setTooltipPositionThree] = useState(0);
  const [tooltipPositionFour, setTooltipPositionFour] = useState(0);
  const [tooltipPositionFive, setTooltipPositionFive] = useState(0);

  const handleSliderChange = (event, num, pos) => {
    const sliderValue = event.target.value;
    setValue(sliderValue);

    // Calculate the tooltip position based on the slider value
    const sliderWidth = event.target.clientWidth - 15;

    const newPercent = sliderValue / num;
    const newPosition = sliderWidth * newPercent;

    switch (pos) {
      case 1:
        setTooltipPositionStart(newPosition + 20);
        break;
      case 2:
        setTooltipPositionEnd(newPosition + 20);
        break;
      case 3:
        setTooltipPositionOne(newPosition + 20);
        break;
      case 4:
        setTooltipPositionTwo(newPosition + 20);
        break;
      case 5:
        setTooltipPositionThree(newPosition + 20);
        break;
      case 6:
        setTooltipPositionFour(newPosition + 20);
        break;
      case 7:
        setTooltipPositionFive(newPosition + 20);
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>Learn | Today</title>
      </Helmet>
      <main className="tutorialpage--container">
        <motion.div
          className="instruction"
          variants={introState}
          initial="hidden"
          animate={isIntro ? "open" : "closed"}
        >
          <div>
            <h3>{instructionState}</h3>
            <span>{supportingState}</span>
            <span>{supportingStateTwo}</span>
          </div>

          <div>
            {clickCounter >= 18 ? (
              <a href={"/tutorial/insight"}>
                <FontAwesomeIcon
                  id="nextIcon"
                  style={{
                    color: "rgba(103, 36, 131, 1)",
                  }}
                  icon={faSquareCaretRight}
                  onClick={clickPlus}
                />
              </a>
            ) : (
              <FontAwesomeIcon
                id="nextIcon"
                style={clickCounter >= 18 && { color: "rgba(103, 36, 131, 1)" }}
                icon={faSquareCaretRight}
                onClick={clickPlus}
              />
            )}
            <a href={"/"}>
              <FontAwesomeIcon id="crossIcon" icon={faSquareXmark} />
            </a>
            <FontAwesomeIcon
              id="nextIcon"
              icon={faSquareCaretLeft}
              onClick={clickMinus}
            />
          </div>
        </motion.div>

        <motion.section
          className="createday--container"
          variants={introState}
          initial="hidden"
          animate={show ? "open" : "closed"}
        >
          <>
            <motion.div
              variants={introState}
              initial="hidden"
              animate={isPrimes ? "open" : "closed"}
            >
              <div className="tile left">
                <div className="dateRow--header">
                  <label
                    className="score label"
                    style={{ opacity: "1", fontWeight: "bold" }}
                  >
                    Priming the Day
                  </label>
                </div>
                <div className="upperDiv">
                  <div className="dateRow">
                    <motion.label
                      htmlFor="loggedDate"
                      className="score label"
                      animate={beforeScore ? "open" : "closed"}
                    >
                      Diary Entry Date:
                    </motion.label>

                    <input
                      id="loggedDate"
                      type="date"
                      value={logDate}
                      onChange={(e) => setLogDate(e.target.value)}
                    />

                    <div className="mobileSaveDiv">
                      <div className="dock node save">
                        <FontAwesomeIcon
                          id="saveIcon"
                          className="icon"
                          icon={faFloppyDisk}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="scoring__row">
                    <div
                      className="score__row"
                      style={
                        startScore === "5"
                          ? { borderRadius: "5px 5px 0px 0px" }
                          : { borderRadius: "5px 5px 5px 0px" }
                      }
                    >
                      <motion.label
                        htmlFor="beforeScore"
                        animate={beforeScore ? "open" : "closed"}
                      >
                        Before filling in my NWD today, I feel...
                      </motion.label>{" "}
                    </div>
                    <motion.input
                      id="beforeScore"
                      className="weekSlider"
                      type="range"
                      min="0"
                      max="5"
                      step="0.1"
                      value={startScore}
                      style={{
                        background: getBackgroundStyleTwo(startScore),
                      }}
                      onChange={(e) => {
                        setStartScore(e.target.value);
                        handleSliderChange(e, 5, 1);
                      }}
                      variants={activeStateRange}
                      animate={beforeScore ? "open" : "closed"}
                    />
                  </div>
                </div>

                <div className="yeartile--day">
                  <div className="tutorial access">
                    <div className="iconDiv--day">
                      {/* <div className="newIcon">
                        <MemoryIcon />
                      </div> */}
                      {thankYou ? (
                        <label htmlFor="thankYou" className="score label">
                          Today, I would like to say thank you to...
                        </label>
                      ) : (
                        <label htmlFor="thankYou" className="score label">
                          Today, I would like to say thank you to...
                        </label>
                      )}
                    </div>
                    <div className="icon_div">
                      <div className="question_mark icon">
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <motion.textarea
                    id="thankYou"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={thankYou}
                    placeholder="Today, I would like to say thank you to..."
                    onChange={(e) => setThankYou(e.target.value)}
                    variants={activeState}
                    initial="hidden"
                    animate={thankYouState ? "open" : "closed"}
                  />
                </div>

                <div className="yeartile--day">
                  <div className="tutorial access">
                    <div className="iconDiv--day">
                      {/* <div className="newIcon">
                        <MemoryIcon />
                      </div> */}
                      {selfLess ? (
                        <label htmlFor="selfLess" className="score label">
                          I will detach from self today by...
                        </label>
                      ) : (
                        <label htmlFor="selfLess" className="score label">
                          I will detach from self today by...
                        </label>
                      )}
                    </div>
                    <div className="icon_div">
                      <div className="question_mark icon">
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <motion.textarea
                    id="selfLess"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={selfLess}
                    placeholder="I will detach from self today by..."
                    onChange={(e) => setSelfLess(e.target.value)}
                    variants={activeState}
                    initial="hidden"
                    animate={selfLessState ? "open" : "closed"}
                  />
                </div>
                <div className="yeartile--day">
                  <div className="tutorial access">
                    <div className="iconDiv--day">
                      {/* <div className="newIcon">
                        <MemoryIcon />
                      </div> */}
                      {mindBody ? (
                        <label htmlFor="mindBody" className="score label">
                          I am pleased to live in my mind & body because...
                        </label>
                      ) : (
                        <label htmlFor="mindBody" className="score label">
                          I am pleased to live in my mind & body because...
                        </label>
                      )}
                    </div>
                    <div className="icon_div">
                      <div className="question_mark icon">
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <motion.textarea
                    id="mindBody"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={mindBody}
                    placeholder="I am pleased to live in my mind and body today because..."
                    onChange={(e) => setMindBody(e.target.value)}
                    variants={activeState}
                    initial="hidden"
                    animate={mindBodyState ? "open" : "closed"}
                  />
                </div>
              </div>
            </motion.div>

            <motion.div
              variants={introState}
              initial="hidden"
              animate={isMoments ? "open" : "closed"}
            >
              <div className="tile centre" style={{ minWidth: "388px" }}>
                <div className="dateRow--header">
                  <label className="score label">My Plan for Day</label>
                </div>
                <div className="moment__submission">
                  <motion.div
                    className={
                      momentOneScore === "10"
                        ? `score__row--moment complete`
                        : "score__row--moment"
                    }
                    variants={activeStateMoment}
                    animate={momentActive ? "open" : "closed"}
                  >
                    <motion.textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentOne}
                      autoComplete="off"
                      variants={fontActiveState}
                      animate={momentActive ? "open" : "closed"}
                      placeholder="Today's first moment is..."
                      onChange={(e) => setMomentOne(e.target.value)}
                    />
                  </motion.div>
                  <motion.input
                    className={momentOneScore ? "weekSlider" : "weekSlider off"}
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentOneScore}
                    style={{ background: getBackgroundStyle(momentOneScore) }}
                    onChange={(e) => {
                      setMomentOneScore(e.target.value);
                      handleSliderChange(e, 10, 3);
                    }}
                    variants={activeStateRange}
                    animate={momentActive ? "open" : "closed"}
                  />
                  {/* <span
                    style={{
                      left: `${tooltipPositionOne}px`,
                      zIndex: "-1",
                      bottom: "26px",
                    }}
                  >
                    {momentOneScore}
                  </span> */}
                </div>

                <div className="moment__submission">
                  <motion.div
                    className={
                      momentTwoScore === "10"
                        ? `score__row--moment complete`
                        : "score__row--moment"
                    }
                    variants={activeStateMoment}
                    animate={momentActive ? "open" : "closed"}
                  >
                    <motion.textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentTwo}
                      autoComplete="off"
                      variants={fontActiveState}
                      animate={momentActive ? "open" : "closed"}
                      placeholder="Today's second moment is..."
                      onChange={(e) => setMomentTwo(e.target.value)}
                    />
                  </motion.div>
                  <motion.input
                    className={momentTwoScore ? "weekSlider" : "weekSlider off"}
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentTwoScore}
                    style={{ background: getBackgroundStyle(momentTwoScore) }}
                    onChange={(e) => {
                      setMomentTwoScore(e.target.value);
                      handleSliderChange(e, 10, 4);
                    }}
                    variants={activeStateRange}
                    animate={momentActive ? "open" : "closed"}
                  />
                  {/* <span
                    style={{
                      left: `${tooltipPositionTwo}px`,
                      zIndex: "-1",
                      bottom: "26px",
                    }}
                  >
                    {momentTwoScore}
                  </span> */}
                </div>

                <div className="moment__submission">
                  <motion.div
                    className={
                      momentThreeScore === "10"
                        ? `score__row--moment complete`
                        : "score__row--moment"
                    }
                    variants={activeStateMoment}
                    animate={momentActive ? "open" : "closed"}
                  >
                    <motion.textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentThree}
                      autoComplete="off"
                      variants={fontActiveState}
                      animate={momentActive ? "open" : "closed"}
                      placeholder="Today's third moment is..."
                      onChange={(e) => setMomentThree(e.target.value)}
                    />
                  </motion.div>
                  <motion.input
                    className={
                      momentThreeScore ? "weekSlider" : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentThreeScore}
                    onChange={(e) => {
                      setMomentThreeScore(e.target.value);
                      handleSliderChange(e, 10, 5);
                    }}
                    style={{
                      background: getBackgroundStyle(momentThreeScore),
                    }}
                    variants={activeStateRange}
                    animate={momentActive ? "open" : "closed"}
                  />
                  {/* <span
                    style={{
                      left: `${tooltipPositionThree}px`,
                      zIndex: "-1",
                      bottom: "26px",
                    }}
                  >
                    {momentThreeScore}
                  </span> */}
                </div>

                <div className="moment__submission">
                  <motion.div
                    className={
                      momentFourScore === "10"
                        ? `score__row--moment complete`
                        : "score__row--moment"
                    }
                    variants={activeStateMoment}
                    animate={momentActive ? "open" : "closed"}
                  >
                    <motion.textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentFour}
                      autoComplete="off"
                      variants={fontActiveState}
                      animate={momentActive ? "open" : "closed"}
                      placeholder="Today's fourth moment is..."
                      onChange={(e) => setMomentFour(e.target.value)}
                    />
                  </motion.div>
                  <motion.input
                    className={
                      momentFourScore ? "weekSlider" : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentFourScore}
                    style={{ background: getBackgroundStyle(momentFourScore) }}
                    onChange={(e) => {
                      setMomentFourScore(e.target.value);
                      handleSliderChange(e, 10, 6);
                    }}
                    variants={activeStateRange}
                    animate={momentActive ? "open" : "closed"}
                  />
                  {/* <span
                    style={{
                      left: `${tooltipPositionFour}px`,
                      zIndex: "-1",
                      bottom: "26px",
                    }}
                  >
                    {momentFourScore}
                  </span> */}
                </div>

                <div className="moment__submission">
                  <motion.div
                    className={
                      momentFiveScore === "10"
                        ? `score__row--moment complete`
                        : "score__row--moment"
                    }
                    variants={activeStateMoment}
                    animate={momentActive ? "open" : "closed"}
                  >
                    <motion.textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentFive}
                      autoComplete="off"
                      variants={fontActiveState}
                      animate={momentActive ? "open" : "closed"}
                      placeholder="Today's fifth moment is..."
                      onChange={(e) => setMomentFive(e.target.value)}
                    />
                  </motion.div>
                  <motion.input
                    className={
                      momentFiveScore ? "weekSlider" : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentFiveScore}
                    style={{ background: getBackgroundStyle(momentFiveScore) }}
                    onChange={(e) => {
                      setMomentFiveScore(e.target.value);
                      handleSliderChange(e, 10, 7);
                    }}
                    variants={activeStateRange}
                    animate={momentActive ? "open" : "closed"}
                  />
                  {/* <span
                    style={{
                      left: `${tooltipPositionFive}px`,
                      zIndex: "-1",
                      bottom: "26px",
                    }}
                  >
                    {momentFiveScore}
                  </span> */}
                </div>
              </div>
            </motion.div>

            <motion.div
              variants={introState}
              initial="hidden"
              animate={isEvenings ? "open" : "closed"}
            >
              <div className="tile right" style={{ minWidth: "388px" }}>
                <div className="dateRow--header">
                  <label
                    className="score label"
                    style={{ opacity: "1", fontWeight: "bold" }}
                  >
                    Reflecting on the Day
                  </label>
                </div>
                <div className="yeartile--day">
                  <div className="tutorial access">
                    <div className="iconDiv--day">
                      {/* <div className="newIcon">
                        <MemoryIcon />
                      </div> */}
                      {rememberToday ? (
                        <label htmlFor="rememberToday" className="score label">
                          The memories from today that will capture it forever
                          are...
                        </label>
                      ) : (
                        <label htmlFor="rememberToday" className="score label">
                          The memories from today that will capture it forever
                          are...
                        </label>
                      )}
                    </div>
                    <div className="icon_div">
                      <div className="question_mark icon">
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <motion.textarea
                    id="rememberToday"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={rememberToday}
                    placeholder="The memories from today that will capture it forever are..."
                    onChange={(e) => setRememberToday(e.target.value)}
                    variants={activeState}
                    initial="hidden"
                    animate={rememberTodayState ? "open" : "closed"}
                  />
                </div>
                <div className="yeartile--day">
                  <div className="tutorial access">
                    <div className="iconDiv--day">
                      {/* <div className="newIcon">
                        <MemoryIcon />
                      </div> */}
                      {leaveBehind ? (
                        <label htmlFor="leaveBehind" className="score label">
                          The thoughts & feelings from today I would like to
                          leave behind are...
                        </label>
                      ) : (
                        <label htmlFor="leaveBehind" className="score label">
                          The thoughts & feelings from today I would like to
                          leave behind are...
                        </label>
                      )}
                    </div>
                    <div className="icon_div">
                      <div className="question_mark icon">
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <motion.textarea
                    id="leaveBehind"
                    initial="hidden"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={leaveBehind}
                    placeholder="The thoughts and feelings from today I would like to leave behind are..."
                    onChange={(e) => setLeaveBehind(e.target.value)}
                    variants={activeState}
                    animate={leaveBehindState ? "open" : "closed"}
                  />
                </div>

                <div className="yeartile--day">
                  <div className="tutorial access">
                    <div className="iconDiv--day">
                      {/* <div className="newIcon">
                        <MemoryIcon />
                      </div> */}
                      {onMyMind ? (
                        <label htmlFor="leaveBehind" className="score label">
                          and finally, anything else that is on my mind
                        </label>
                      ) : (
                        <label htmlFor="leaveBehind" className="score label">
                          and finally, anything else that is on my mind
                        </label>
                      )}
                    </div>
                    <div className="icon_div">
                      <div className="question_mark icon">
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <motion.textarea
                    id="leaveBehind"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={onMyMind}
                    placeholder="Not an easy day today."
                    onChange={handleChange_onMyMind}
                    variants={activeState}
                    animate={leaveBehindState ? "open" : "closed"}
                  />
                </div>

                <div className="scoring__row" style={{ borderBottom: "none" }}>
                  <div
                    className="score__row"
                    style={
                      endScore === "5"
                        ? { borderRadius: "5px 5px 0px 0px" }
                        : { borderRadius: "5px 5px 5px 0px" }
                    }
                  >
                    <motion.label
                      htmlFor="endScore"
                      initial="hidden"
                      animate={finalScore ? "open" : "closed"}
                    >
                      After the day described above, I feel...{" "}
                    </motion.label>{" "}
                  </div>
                  <motion.input
                    id="endScore"
                    className={endScore ? "weekSlider" : "weekSlider off"}
                    type="range"
                    min="0"
                    max="5"
                    step="0.1"
                    value={endScore}
                    style={{ background: getBackgroundStyleTwo(endScore) }}
                    onChange={(e) => {
                      setEndScore(e.target.value);
                      handleSliderChange(e, 5, 2);
                    }}
                    variants={activeStateRange}
                    initial="hidden"
                    animate={finalScore ? "open" : "closed"}
                  />
                </div>
              </div>
            </motion.div>
          </>
        </motion.section>
      </main>
      <TestFooter />
    </>
  );
}
