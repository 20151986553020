import {
  WEEKS_CREATE_FAIL,
  WEEKS_CREATE_REQUEST,
  WEEKS_CREATE_SUCCESS,
  WEEKS_LIST_FAIL,
  WEEKS_LIST_REQUEST,
  WEEKS_LIST_SUCCESS,
  WEEKS_UPDATE_FAIL,
  WEEKS_UPDATE_REQUEST,
  WEEKS_UPDATE_SUCCESS,
  WEEKS_SINGLE_REQUEST,
  WEEKS_SINGLE_SUCCESS,
  WEEKS_SINGLE_FAIL,
} from "../constants/weekConstants";
import axios from "axios";

export const listWeeks = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: WEEKS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `https://nwd-backend.herokuapp.com/api/weeks/`,
      config
    );

    dispatch({
      type: WEEKS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: WEEKS_LIST_FAIL,
      payload: message,
    });
  }
};

export const getWeekbyIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WEEKS_SINGLE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `https://nwd-backend.herokuapp.com/api/weeks/week/${id}`,
      config
    );

    dispatch({
      type: WEEKS_SINGLE_SUCCESS,
      payload: data,
    });

    return data;
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: WEEKS_SINGLE_FAIL,
      payload: message,
    });
  }
};

export const createWeekAction = (objectives) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WEEKS_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `https://nwd-backend.herokuapp.com/api/weeks/week/create`,
      { objectives },
      config
    );

    await dispatch({
      type: WEEKS_CREATE_SUCCESS,
      payload: data,
    });

    return data;
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: WEEKS_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateWeekAction =
  (id, objectives) => async (dispatch, getState) => {
    try {
      dispatch({
        type: WEEKS_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `https://nwd-backend.herokuapp.com/api/weeks/week/${id}`,
        {
          objectives,
        },
        config
      );

      await dispatch({
        type: WEEKS_UPDATE_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: WEEKS_UPDATE_FAIL,
        payload: message,
      });
    }
  };
