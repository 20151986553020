import React, { useEffect, useRef } from "react";

const AutoResizeTextArea = ({
  value,
  onChange,
  id,
  className,
  wrap,
  autoComplete,
  placeholder,
  name,
}) => {
  const textAreaRef = useRef(null);

  const adjustHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // Reset height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set to new height
    }
  };

  useEffect(() => {
    adjustHeight();
  });

  return (
    <textarea
      id={id}
      ref={textAreaRef}
      value={value}
      name={name}
      onChange={(e) => {
        onChange(e);
      }}
      style={{
        resize: "none",
      }}
      className={className}
      placeholder={placeholder}
      wrap={wrap}
      autoComplete={autoComplete}
    />
  );
};

export default AutoResizeTextArea;
