// React
import React, { useState, useEffect } from "react";

// External Components
import { motion, useDragControls } from "framer-motion";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./Dropable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faSquareCaretDown,
  faSquareCaretUp,
  faSquareXmark,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

// Internal  Components
import AutoResizeTextArea from "../../../AutoResizeTextarea/AutosizeTextarea";
import { backgroundColor } from "@xstyled/styled-components";

function Callout({
  id,
  steps,
  description,
  notes,
  overlay,
  calloutActive,
  columnId,
  subTaskIndex,
  onDragEndSteps,
  addStep,
  handleCalloutDisplay,
  handleIndexChange,
  updateDescription,
  updateStep,
  updateNotes,
  toggleStep,
  toggleOverlay,
  deleteStep,
  deleteObjective,
  deleteSubtask,
  stepsLength,
  viewType,
}) {
  const [warning, setWarning] = useState(false);

  const dragControls = useDragControls();

  const startDrag = (event) => {
    dragControls.start(event);
  };

  return (
    <>
      <div
        className={
          calloutActive ? "calloutContainer--overlay" : "calloutContainer"
        }
      >
        <motion.div
          className={
            calloutActive
              ? `objective__callout--active ${columnId}`
              : "objective__callout"
          }
          style={{ zIndex: `3${subTaskIndex}` }}
        >
          <div className="callout__header">
            <input
              className="callout__header--input"
              type="text"
              value={description}
              autoComplete="off"
              onChange={(event) =>
                updateDescription(event, columnId, subTaskIndex)
              }
            ></input>

            <FontAwesomeIcon
              className="callout--icon"
              icon={faSquareXmark}
              data-display-name="callout"
              onClick={(e) => {
                handleCalloutDisplay(e);
                toggleOverlay(false);
              }}
            />
          </div>

          <DragDropContext
            onDragEnd={(result) =>
              onDragEndSteps(result, columnId, subTaskIndex)
            }
          >
            <StrictModeDroppable
              droppableId={`Steps-List-${columnId}-${subTaskIndex}`}
              type="STEPS"
            >
              {(provided) => (
                <div
                  className="callout__actions"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {steps &&
                    steps.length > 0 &&
                    steps.map((d, i) => (
                      <Draggable key={d.id} draggableId={d.id} index={i}>
                        {(provided) => (
                          <div
                            className="objective__col--wrapper"
                            style={{ width: "100%" }}
                            ref={provided.innerRef}
                          >
                            <div
                              className={
                                d.completed === 0
                                  ? "objective__col--task"
                                  : "objective__col--task-active"
                              }
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="task__column--wrapper">
                                <div className="task__column">
                                  <div className="task__icon--wrapper">
                                    <div
                                      className={
                                        d.completed === 0
                                          ? "task__radio"
                                          : "task__radio--active"
                                      }
                                      onClick={() =>
                                        toggleStep(subTaskIndex, i, columnId)
                                      }
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div className="task__container">
                                {d.action && (
                                  <AutoResizeTextArea
                                    className={
                                      d.completed === 0
                                        ? "task--input"
                                        : "task--input completed"
                                    }
                                    value={d.action}
                                    onChange={(event) =>
                                      updateStep(
                                        columnId,
                                        subTaskIndex,
                                        i,
                                        event
                                      )
                                    }
                                  />
                                )}
                              </div>

                              <FontAwesomeIcon
                                className="objective__col--icon trash"
                                icon={faTrash}
                                onClick={() => {
                                  deleteStep(columnId, subTaskIndex, i);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}

                  {provided.placeholder}
                  <div className="objective__col--name">
                    <FontAwesomeIcon
                      className="objective__col--icon"
                      icon={faPlus}
                    />
                    <input
                      type="text"
                      className="col__name--input"
                      placeholder="Add a new step"
                      autoComplete="off"
                      name={`${columnId}-${subTaskIndex}`}
                      // onChange={(e) => setAction(e.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" && !event.repeat) {
                          event.preventDefault();
                          addStep(event, columnId, id, stepsLength);
                        }
                      }}
                    />
                    <FontAwesomeIcon
                      className="objective__col--icon ellipsis"
                      icon={faEllipsisVertical}
                    />
                  </div>
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
          <div className="callout__notes">
            <textarea
              className="notes"
              placeholder="Add Notes"
              value={notes}
              onChange={(event) => updateNotes(columnId, subTaskIndex, event)}
            />

            <div
              className="callout__button"
              data-display-name="callout"
              onClick={(e) => {
                deleteSubtask(columnId, subTaskIndex);
                handleCalloutDisplay(e);
              }}
            >
              Delete Task
            </div>
          </div>
        </motion.div>
        <div
          className={warning ? "warning__callout--active" : "warning__callout"}
        >
          <strong>Are you sure you want to delete this objective?</strong>
          <div className="warning__button--row">
            <div
              className="callout__button"
              onClick={() => {
                deleteObjective(columnId);
                setWarning(false);
              }}
            >
              Yes
            </div>
            <div className="callout__button" onClick={() => setWarning(false)}>
              No
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Callout;
