const quoteObj = [
  {
    quote:
      "The shoe that fits one person pinches another; there is no recipe for living that suits all cases.",
    author: "Carl Jung",
    searchTerms: ["living", "meaning", "searching"],
  },
  {
    quote: "Reality is merely an illusion, albeit a very persistent one.",
    author: "Albert Einstein",
    searchTerms: ["reality", "illusion", "relativity"],
  },
  {
    quote:
      "These individuals have riches just as we say that we “have fever”, when really the fever has us.",
    author: "Seneca",
    searchTerms: ["wealth", "riches", "possession"],
  },
  {
    quote:
      "What you want, wants you too. Just get out of the way and let it come through.",
    author: "Bronnie Ware",
    searchTerms: ["want", "manifestation", "detachment"],
  },
  {
    quote:
      "It is not enough to take steps which may some day lead to a goal; each step must be itself a goal and a step likewise.",
    author: "Goethe",
    searchTerms: ["goals", "goal setting", "progress"],
  },
  {
    quote:
      "Freedom can be best defined as the opportunity for self-discipline.",
    author: "Eisenhower",
    searchTerms: ["freedom", "discipline", "opportunity"],
  },
  {
    quote: "Tis easier to know how to speak than how to be silent",
    author: "Thomas Fuller",
    searchTerms: ["silence", "speaking", "speak"],
  },
  {
    quote:
      "We must each find our separate meaning, the persuasion of our days, until we meet in the meaning of the world",
    author: "Christopher Fry",
    searchTerms: ["searching", "meaning", "world"],
  },
  {
    quote:
      "Meanings change amazingly. When people get accustomed to horrors, these form the foundation for good style",
    author: "Boris Pasternack",
    searchTerms: ["meaning", "trends", "style"],
  },
  {
    quote:
      "The meaning of things lies not in the things themselves, but in our attitude towards them",
    author: "Saint Exupery ",
    searchTerms: ["meaning", "reality", "attitude"],
  },
  {
    quote: "The mind knows no answers. The heart knows no questions.",
    author: "Buddha",
    searchTerms: ["heart", "heart", "answers"],
  },
  {
    quote: "Life loves the one who dares to live it.",
    author: "Maya Angelou",
    searchTerms: ["life", "loves", "dares"],
  },
  {
    quote:
      "Freedom suppressed and again regained bites with keener fangs then freedom never endangered.",
    author: "Cicero",
    searchTerms: ["freedom", "oppression", "bites"],
  },
  {
    quote:
      "A man that is young in years may be old in hours, if he have lost no time.",
    author: "Francis Bacon",
    searchTerms: ["age", "wisdom", "time"],
  },
  {
    quote:
      "We do but learn to-day what our better advanced judgements will unteach us tomorrow.",
    author: "Sir Thomas Browne",
    searchTerms: ["learn", "unlearn", "teach"],
  },
  {
    quote: "We cannot all do everything.",
    author: "Virgil",
    searchTerms: ["reality", "everything", "doing"],
  },
  {
    quote:
      "I never saw an ugly thing in my life, for let the form of an object be what it may - light, shade, perspective, will always make it beautiful.",
    author: "John Constable",
    searchTerms: ["beauty", "form", "ugly"],
  },
  {
    quote:
      "As some point in life, the worlds beauty becomes enough. You don't need to photograph, paint or even remember it - it is enough.",
    author: "Toni Morrison",
    searchTerms: ["beauty", "photograph", "enough"],
  },
  {
    quote: "Suit the action to the word, the word to the action.",
    author: "William Shakespeare",
    searchTerms: ["integrity", "acting", "word"],
  },
  {
    quote: "Better to light a candle than curse the darkness.",
    author: "Peter Benenson",
    searchTerms: ["hope", "action", "optimism"],
  },
  {
    quote:
      "Get the advice of everybody whose advise is worth having - they are very few - and then do what you think best yourself.",
    author: "Charles Stewart Parnell",
    searchTerms: ["advice", "action", "commitment"],
  },
  {
    quote:
      "All ambitions are lawful except those which climb upwards on the miseries or credulities of mankind.",
    author: "Joseph Conrad",
    searchTerms: ["ambtion", "moral", "climb"],
  },
  {
    quote: "There is nothing worse than a brilliant beginning.",
    author: "Pablo Picasso",
    searchTerms: ["beginning", "brilliant", "worse"],
  },
  {
    quote:
      "He that will not apply new remedies must expect new evils; for time is the greatest innovator.",
    author: "Francis Bacon",
    searchTerms: ["change", "time", "remedy"],
  },
  {
    quote: "You can’t step twice into the same river.",
    author: "Heraclitus",
    searchTerms: ["change", "time", "mindful"],
  },
  {
    quote:
      "Mr Bond, they have a saying in Chicago, ‘Once is happenstance. Twice is coincidence. The third time is enemy action.",
    author: "Goldfinger",
    searchTerms: ["chance", "trend", "reality"],
  },
  {
    quote: "A man's character is his fate.",
    author: "Heraclitus",
    searchTerms: [""],
  },
  {
    quote:
      "A propensity to hope and joy is real riches; one to fear and sorry, real poverty.",
    author: "David Hume",
    searchTerms: [""],
  },
  {
    quote:
      "Action is consolatory. It is the enemy of thought and the friend of flattering illusions.",
    author: "Joseph Conrad",
    searchTerms: [""],
  },
  {
    quote:
      "It is of no consequence what other people think of you. What matters is what you think of them.",
    author: "Gore Vidal",
    searchTerms: [""],
  },
  {
    quote:
      "There is no despair so absolute as that which comes with the first moments of our first great sorrow, when we have not yet know what is it to have suffered and be healed, to have despaired and have recovered.",
    author: "George Eliot",
    searchTerms: [""],
  },
  {
    quote:
      "Look for what's missing. Many advisers can tell a president how to improve what's proposed, or what's gone amiss. Few are able to see what isn't there.",
    author: "Donald Rumsfeld",
    searchTerms: [""],
  },
  {
    quote: "A deception that elevates us is dearer than a host of low truths.",
    author: "Alexander Pushkin",
    searchTerms: [""],
  },
  {
    quote: "Dangers, by deing despised, grow great.",
    author: "Edmund Burke",
    searchTerms: [""],
  },
  {
    quote:
      "I maintain that two and two would continue to make four, in spite of the whine of thre amateur for three, or the cry of the critic for five.",
    author: "James McNeil Whistler",
    searchTerms: [""],
  },
  {
    quote:
      "A thing itself never expresses anything. It is the relation between things that gives meaning to them and formulates a thought. A thought functionsonly as a fragmentary part in the formulation of an idea",
    author: "Hans Hofmann",
    searchTerms: [""],
  },
  {
    quote:
      "Someone to tell it to is one of the fundamental needs of human beings.",
    author: "Miles Franklin",
    searchTerms: [""],
  },
  {
    quote: "A good conscience is a continual christmas.",
    author: "Benjamin Franklin",
    searchTerms: [""],
  },
  {
    quote:
      "O pure and noble conscience, how bitter a sting to thee is a little fault.",
    author: "Dante",
    searchTerms: [""],
  },
  {
    quote:
      "The means prepare the end, and the end is what the means have made it.",
    author: "John Morley",
    searchTerms: [""],
  },
  {
    quote:
      "A good man would prefer to be defeated than to defeat injustice by evil means.",
    author: "Sallust",
    searchTerms: [""],
  },
  {
    quote: "Reality is merely an illusion, albeit a very persistent one.",
    author: "Albert Einstein",
    searchTerms: [""],
  },
  {
    quote:
      "Our consciousness is not actually yours of mine, it is the consciousness of man evolved, grown, accumulated through many, many centuries. [...] When one realises this, our responsibility becomes extraordinarily important.",
    author: "Krishamurti",
    searchTerms: [""],
  },
  {
    quote: "There are thousands of lives in one single life.",
    author: "Swami Prajnanpad",
    searchTerms: [""],
  },
  {
    quote:
      "If most of us remain ignorant of ourselves, it is becaused self-knowledge is painful and we prefer the pleasures of illusion.",
    author: "Aldous Huxley",
    searchTerms: [""],
  },
  {
    quote:
      "If you see the soul in every living being, you see truly. If you see the immortality in the heart of every mortal being, you see truly.",
    author: "The Bhagavad Gita",
    searchTerms: [""],
  },
  {
    quote: "To free oneself from knowledge is to die, and thus one lives.",
    author: "Krishamurti",
    searchTerms: [""],
  },
  {
    quote:
      "Journalist: 'What do you think of modern civilisation?'. Ghandi: 'I think it would be a good idea.'",
    author: "Interview Excerpt",
    searchTerms: [""],
  },
  {
    quote: "He not busy being born is busy dying.",
    author: "It's Alright, Ma (I'm Only Bleeding) - Bob Dylan",
    searchTerms: [""],
  },
  {
    quote:
      "Most of the change we think we see in life is due to truths being in and out of favor.",
    author: "Robert Frost",
    searchTerms: [""],
  },
  {
    quote:
      "Consistentcy is contrary to nature, contrary to life. The only completely consistent people are the dead.",
    author: "Aldous Huxley",
    searchTerms: [""],
  },
  {
    quote:
      "God, give us the serenity to accept what cannot be changed. Give us the courage to change what should be changed. Give us the wisdom to distinguish one from the other.",
    author: "Reinhold Niebuhr",
    searchTerms: [""],
  },
  {
    quote:
      "Anger in its time and place, may assume a kind of grace. It must have some reason in it, and not last beyond a minute.",
    author: "Charles Lamb",
    searchTerms: [""],
  },
  {
    quote:
      "What we call the beginning is often the end and to make an end is to make a beginning. The end is where we start from.",
    author: "T.S. Eliot",
    searchTerms: [""],
  },
  {
    quote:
      "Ah, what a dusty answer gets the soul when hot for certainties in this our life",
    author: "Geprge Meredith",
    searchTerms: [""],
  },
  {
    quote:
      "Doubt may be an uncomfortable position, but certainty is a ridiculous one.",
    author: "Voltaire",
    searchTerms: [""],
  },
  {
    quote: "The absent are always in the wrong.",
    author: "Philippe Nericaut Destouches",
    searchTerms: [""],
  },
  {
    quote:
      "The first principle is that you must not fool yourself, and you are the easiest person to fool.",
    author: "Richard P. Feynman",
    searchTerms: [""],
  },
  {
    quote:
      "He has, indeed, done it very well; but it is a foolish thing well done.",
    author: "Samuel Johnson",
    searchTerms: [""],
  },
  {
    quote: "Fame is an accident; merit a thing absolute.",
    author: "Herman Melville",
    searchTerms: [""],
  },
  {
    quote:
      "The perfect way is only difficult for those who pick and choose. Do not like, do not dislike; all will then be clear. Make a hair breadths difference and all heaven and earth are set apart. If you wnat the truth to stand clear before you, never be for or against.",
    author: "Sen Zhong",
    searchTerms: [""],
  },
  {
    quote:
      "The primary job of artists is to create places within which you can have feelings.",
    author: "Brian Eno",
    searchTerms: [""],
  },
  {
    quote: "Art is meant to distub; science to reassure.",
    author: "Georges Braque",
    searchTerms: [""],
  },
  {
    quote:
      "When you have a creative gift, it is like someone has given you a horse. You either learn to ride it, or it kills you.",
    author: "Jerry Seinfeld",
    searchTerms: [""],
  },
  {
    quote:
      "We must all face the choice between what is right and what is easy.",
    author: "Albus Dumbledore",
    searchTerms: [""],
  },
  {
    quote: "In the Ant's House, the dew is the flood.",
    author: "Persian Proverb",
    searchTerms: [""],
  },
  {
    quote:
      "It is of no consequence what other people think of you. What matters is what you think of them.",
    author: "Gove Vidal",
    searchTerms: [""],
  },
];

export default quoteObj;
