// React
import React, { useState, useRef, useEffect } from "react";

// Internal Components
import { isUrl } from "../../../../utils/UtilityFunctions";
import Urgency from "./Urgency";
import AutoResizeTextArea from "../../../AutoResizeTextarea/AutosizeTextarea";
import { WeekIcon } from "../../../Icons/Icon";

// External Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCalendarCheck,
  faEllipsisVertical,
  faTrash,
  faSquareMinus,
} from "@fortawesome/free-solid-svg-icons";
import {
  faSquarePlus,
  faCalendar,
  fa,
} from "@fortawesome/free-regular-svg-icons";

function Input({
  task,
  subTaskIndex,
  title,
  display,
  toggleSubTask,
  toggleOverlay,
  updateDescription,
  handleCalloutDisplay,
  handleScoreChange,
  handleUrgencyChange,
}) {
  const [deadline, setDeadline] = useState();

  const dateShortener = (date) => {
    let newDate = new Date(date);

    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();

    return `${day}/${month}`;
  };

  useEffect(() => {
    setDeadline(dateShortener(task.deadline));
  }, [task.deadline]);

  return (
    <>
      <div
        className={
          task.completed === 0
            ? "objective__col--task"
            : "objective__col--task-active"
        }
      >
        <div className="task__column--wrapper">
          <div className="task__column">
            <div className="task__icon--wrapper">
              <div
                className={
                  task.completed === 0 ? "task__radio" : "task__radio--active"
                }
                onClick={() => {
                  toggleSubTask(subTaskIndex, title);
                  handleScoreChange(title);
                }}
              ></div>
            </div>
            <div className="task__icon--wrapper">
              {task.deadline ? (
                <div
                  data-display-name="remove"
                  onClick={(e) => {
                    handleCalloutDisplay(e, "deadline");
                    handleUrgencyChange(e, subTaskIndex, title, "deadline");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="row--icon calendar"
                  />
                </div>
              ) : (
                <div
                  data-display-name="deadline"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCalloutDisplay(e);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="row--icon calendar"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="task__container">
          {isUrl(task.description) ? (
            <a href={task.description} target="_blank" rel="noreferrer">
              <AutoResizeTextArea
                className={
                  task.completed === 0 ? "task--input" : "task--input completed"
                }
                value={task.description}
                onChange={(event) =>
                  updateDescription(event, title, subTaskIndex)
                }
                onClick={() => toggleSubTask(subTaskIndex, title)}
                readOnly
              ></AutoResizeTextArea>
            </a>
          ) : (
            <AutoResizeTextArea
              className={
                task.completed === 0 ? "task--input" : "task--input completed"
              }
              value={task.description}
              onChange={(event) =>
                updateDescription(event, title, subTaskIndex)
              }
              title={title}
            />
          )}
          {(display.deadline || display.urgency) && (
            <div className="task__row">
              <div className="row--item--urgency">
                {display.urgency && (
                  <Urgency
                    task={task}
                    title={title}
                    subTaskIndex={subTaskIndex}
                    handleUrgencyChange={handleUrgencyChange}
                  />
                )}
              </div>
              <div className="row--item--date">
                {display.deadline &&
                  (task.deadline ? (
                    <span
                      className="fucksake"
                      style={{
                        fontWeight: "bold",
                        height: "100%",
                        padding: "2px 4px 0 4px ",
                        margin: "0",
                      }}
                    >
                      {deadline}
                    </span>
                  ) : (
                    <input
                      type="date"
                      className="fucksake"
                      value={task.deadline}
                      name={title}
                      onChange={(e) => {
                        handleUrgencyChange(e, subTaskIndex, title);
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>

        <div className="task__column--wrapper">
          <div className="task__column">
            <div className="task__icon--wrapper">
              <FontAwesomeIcon
                className="row--icon"
                icon={faEllipsisVertical}
                data-display-name="callout"
                onClick={(e) => {
                  toggleOverlay(true);
                  handleCalloutDisplay(e);
                }}
              />
            </div>
            <div className="task__icon--wrapper">
              {task.urgency ? (
                <div
                  role="button"
                  data-display-name="remove"
                  onClick={(e) => {
                    handleCalloutDisplay(e, "urgency");
                    handleUrgencyChange(e, subTaskIndex, title, "urgency");
                  }}
                >
                  <FontAwesomeIcon icon={faSquareMinus} className="row--icon" />
                </div>
              ) : (
                <div
                  data-display-name="urgency"
                  onClick={(e) => {
                    toggleOverlay(true);
                    handleCalloutDisplay(e);
                  }}
                >
                  <FontAwesomeIcon icon={faSquarePlus} className="row--icon" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Input;
