// React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

// User Actions
import { updateProfile } from "../../actions/userActions";
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";
import { listDays } from "../../actions/dayActions";

// Internal Components
import Header from "../../layouts/Header/Header";
import TileLoading from "../../components/Loading/TileLoading";
import TestFooter from "../../layouts/Footer/TestFooter";

// External Packages
import emailjs from "@emailjs/browser";
import { Button } from "react-bootstrap";
import * as d3 from "d3";

// Stylesheet
import "./profiletest.css";

export default function ProfileScreen({ history }) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);

  const [paycPicker, setPaycPicker] = useState(
    "https://www.patreon.com/nowasteddays/membership"
  );
  const [paycName, setPaycName] = useState("");
  const [paymentMessage, setPaymentMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const weekList = useSelector((state) => state.weekList);
  const { weeks } = weekList;

  const dayList = useSelector((state) => state.dayList);
  const { days } = dayList;

  useEffect(() => {
    dispatch(listYears());
  }, [dispatch, userInfo, history]);

  useEffect(() => {
    dispatch(listWeeks());
  }, [dispatch, userInfo, history]);

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo, history]);

  const userUpdate = useSelector((state) => state.userUpdate);
  const { loading, error, success } = userUpdate;

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else {
      setName(userInfo.name);
      setEmail(userInfo.email);
    }
  }, [history, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProfile({
        name,
        email,
        password,
        confirmPassword,
      })
    );
  };

  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [subjectLine, setSubjectLine] = useState("");
  const [body, setBody] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rt1yckn",
        "template_4oxlqrh",
        form.current,
        "1YKVv6BHraqDPites"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
    navigate("/");
  };

  const [pageLoading, setPageLoading] = useState(true);

  const loadingTimeout = () => {
    setTimeout(() => {
      setPageLoading(false);
    }, 5000);
  };

  useEffect(() => {
    loadingTimeout();
  });

  const [cursorDisplayState, setCursorDisplayState] = useState(false);
  const [helpStateIntro, setHelpStateIntro] = useState(false);

  const cursorDisplayToggle = () => {
    setCursorDisplayState((cursorDisplayState) => !cursorDisplayState);
  };

  const tutorialDisplayToggle = () => {
    setHelpStateIntro((helpStateIntro) => !helpStateIntro);
  };

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const avgArray = [];

  days && days.map((each) => avgArray.push(each.endScore));

  const ZeroRemove = () => {
    let lastItem = avgArray.length - 1;

    if (avgArray[lastItem] === 0) {
      return avgArray.pop();
    } else {
      return avgArray;
    }
  };

  ZeroRemove();

  const daysLogged = d3.count(avgArray, (d) => d);

  const daysRemaining = 10 - daysLogged;

  const avgArrayStreak = [];

  days &&
    days.map((each) =>
      avgArrayStreak.push({ date: each.logDate, value: each.endScore })
    );

  function countConsecutiveDays(dataArray) {
    let currentStreak = 0;
    let maxStreak = 0;

    for (let i = 0; i < dataArray.length; i++) {
      if (i === 0 || isPreviousDay(dataArray[i], dataArray[i - 1])) {
        currentStreak++;
      } else {
        maxStreak = Math.max(maxStreak, currentStreak);
        currentStreak = 1;
      }
    }
    return Math.max(maxStreak, currentStreak);
  }

  function isPreviousDay(currentDay, previousDay) {
    const currentDate = new Date(currentDay.date);
    const previousDate = new Date(previousDay.date);

    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
    return currentDate - previousDate === oneDay;
  }

  const consecutiveDays = countConsecutiveDays(avgArrayStreak);

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (vw > 320) {
        setIsVisible(window.scrollY <= 0);
      } else {
        setIsVisible(window.scrollY <= 1000);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const dataArray = [
    { date: "09/01/2023", value: 1 },
    { date: "09/02/2023", value: 1 },
    { date: "09/04/2023", value: 1 },
    { date: "09/05/2023", value: 1 },
    { date: "09/06/2023", value: 1 },
    { date: "09/07/2023", value: 1 },
    { date: "09/09/2023", value: 1 },
    { date: "09/10/2023", value: 1 },
    { date: "09/11/2023", value: 1 },
    { date: "09/12/2023", value: 1 },
    { date: "09/13/2023", value: 1 },
    { date: "09/14/2023", value: 1 },
    { date: "09/15/2023", value: 1 },
    { date: "09/16/2023", value: 1 },
    { date: "09/17/2023", value: 1 },
    { date: "09/18/2023", value: 1 },
    { date: "09/20/2023", value: 1 },
    { date: "09/21/2023", value: 1 },
    { date: "09/22/2023", value: 1 },
  ];

  function calculateMaxConsecutiveDays(dataArray) {
    if (dataArray.length === 0) {
      return 0; // If the array is empty, there are no consecutive days.
    }

    // Convert date strings to actual Date objects for easier comparison.
    const dates = dataArray.map((item) => new Date(item.date));

    let maxConsecutiveDays = 1; // Minimum value is 1 since there's at least one day of data.

    for (let i = dates.length - 1; i > 0; i--) {
      const diffInMilliseconds = dates[i] - dates[i - 1];
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

      if (diffInMilliseconds <= oneDayInMilliseconds) {
        maxConsecutiveDays++;
      } else {
        break; // Stop counting consecutive days if a gap is found.
      }
    }

    return maxConsecutiveDays;
  }

  const maxConsecutiveDays = calculateMaxConsecutiveDays(avgArrayStreak);

  return (
    <>
      <Header variant="light" />
      <>
        <main className="mainProfileScreen">
          {pageLoading ? (
            <TileLoading />
          ) : (
            <div className="tabContainer profile">
              <Tabs
                id="uncontrolled-tab-example"
                defaultActiveKey="update"
                className="tabs profile"
              >
                {/* <Tab
                  className="tab"
                  eventKey="stats"
                  title={vw < 500 ? "Stats" : "Statistics"}
                >
                  <div className="container stats">
                    <div className="stat">
                      <span>Total Days Logged:</span>
                      <span>{daysLogged}</span>
                    </div>
                    <div className="stat">
                      <span>Current Streak:</span>
                      <span>{maxConsecutiveDays}</span>
                    </div>
                    <div className="stat">
                      <span>Longest Streak:</span>
                      <span>{consecutiveDays}</span>
                    </div>
          
                    <hr></hr>
                    {daysLogged >= 10 || maxConsecutiveDays >= 5 ? (
                      <div className="challenge">
                        <div>
                          <h6>You have completed the #NWDsChallenge! </h6>
                        </div>
                        <div>
                          <span>
                            You have either logged more than 10 total days or
                            completed a streak of 5 days!
                          </span>
                        </div>
                        <div>
                          <span>
                            Share a post on social using the hashtag{" "}
                            <a
                              href={"https://www.instagram.com/nowasteddays"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              #NWDsChallenge
                            </a>{" "}
                            if you liked the experience or{" "}
                            <a
                              href={"https://forms.gle/NUoRqCj6XtvKSAAX9"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              complete our quick feedback form
                            </a>{" "}
                            if you didn't.
                          </span>
                        </div>
                        <div>
                          <span>...and we will send you £10!*</span>
                        </div>
                      </div>
                    ) : (
                      <div className="challenge">
                        <div>
                          <h6>Have you heard of the NWDs Challenge?</h6>
                        </div>
                        <div>
                          <span>
                            If you log 5 consecutive days or 10 total days in
                            your diary and then...
                          </span>
                        </div>
                        <div>
                          <span>
                            Share a post on social using the hashtag{" "}
                            <a
                              href={"https://www.instagram.com/nowasteddays"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              #NWDsChallenge
                            </a>{" "}
                            if you liked the experience or{" "}
                            <a
                              href={"https://forms.gle/NUoRqCj6XtvKSAAX9"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              complete our quick feedback form
                            </a>{" "}
                            if you didn't.
                          </span>
                        </div>
                        <div>
                          <span>...and we will send you £10!*</span>
                        </div>
                      </div>
                    )}
                  </div>
                </Tab> */}

                <Tab
                  className="tab"
                  eventKey="update"
                  title={vw < 500 ? "Profile Update" : "Profile Update"}
                >
                  <div className="container profile">
                    <form
                      className="updateProfileForm"
                      onSubmit={submitHandler}
                    >
                      <div
                        className="container email"
                        style={{ marginBottom: "12px" }}
                      >
                        <label htmlFor="updateName">Name</label>
                        <input
                          id="updateName"
                          type="text"
                          className="textInput"
                          value={name}
                          placeholder="name"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div
                        className="container email"
                        style={{ marginBottom: "12px" }}
                      >
                        <label htmlFor="email">Email</label>
                        <input
                          id="email"
                          className="textInput"
                          type="text"
                          value={email}
                          placeholder="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div
                        className="container password"
                        style={{ marginBottom: "12px" }}
                      >
                        <label htmlFor="password">Password</label>
                        <input
                          id="password"
                          className="textInput"
                          type="password"
                          value={password}
                          placeholder="New Password"
                          onChange={(e) => setPassword(e.target.value)}
                          autocomplete="off"
                        />
                      </div>
                      <div
                        className="container confirmPassword"
                        style={{ marginBottom: "12px" }}
                      >
                        <label htmlFor="confPassword">Confirm Password</label>
                        <input
                          id="confPassword"
                          className="textInput"
                          type="password"
                          value={confirmPassword}
                          placeholder="Confirm password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          autocomplete="off"
                        />
                      </div>
                      <div className="profileButtonDiv">
                        <Button className="updateProfileButton" type="submit">
                          Update
                        </Button>
                      </div>
                    </form>
                  </div>
                </Tab>
                <Tab className="tab" eventKey="contact" title="Contact">
                  <div className="container contact">
                    <form
                      className="updateProfileForm"
                      ref={form}
                      onSubmit={sendEmail}
                    >
                      <div className="container name">
                        <label htmlFor="contactName">Name:</label>
                        <input
                          id="updateName"
                          type="text"
                          value={senderName}
                          className="textInput"
                          name="from_name"
                          placeholder="Your Name"
                          onChange={(e) => setSenderName(e.target.value)}
                        />
                      </div>
                      <div className="container email">
                        <label htmlFor="email">Email</label>
                        <input
                          className="textInput"
                          type="text"
                          value={senderEmail}
                          name="from_email"
                          placeholder="Email"
                          onChange={(e) => setSenderEmail(e.target.value)}
                        />
                      </div>
                      <div className="container email">
                        <label htmlFor="contactEnquirySelect">
                          Enquiry Type:{" "}
                        </label>
                        <select
                          id="contactEnquirySelect"
                          className="enquirySelect"
                          form="contactForm"
                          name="enquiry_type"
                        >
                          <option value="Bug Found">Report a Bug</option>
                          <option value="Question">Ask a Question</option>
                          <option value="Suggestion">
                            Recommend a New Feature
                          </option>
                          <option value="Suggestion">
                            Volunteer Your Skills
                          </option>
                          <option value="Complaint">Leave a Complaint</option>
                          <option value="Testimony">
                            NWDs Challenge Testimony
                          </option>
                          <option value="data_access">
                            Data Access Request
                          </option>
                          <option value="account_delete">
                            Account Deletion Request
                          </option>
                          <option value="Other">Something Else</option>
                        </select>
                      </div>

                      <div className="container email">
                        <label htmlFor="messageArea">Message</label>
                        <textarea
                          id="messageArea"
                          type="password"
                          value={body}
                          name="message"
                          className="textInput"
                          rows="8"
                          placeholder="Message"
                          onChange={(e) => setBody(e.target.value)}
                        />
                      </div>

                      <Button className="sendButton" type="submit" value="Send">
                        Send Message
                      </Button>
                    </form>
                  </div>
                </Tab>
              </Tabs>
            </div>
          )}
        </main>
        <TestFooter />
      </>
    </>
  );
}
